/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimResult } from './claimResult';


export interface ClientResult { 
    allowOfflineAccess?: boolean;
    identityTokenLifetime?: number;
    enableLocalLogin?: boolean;
    claims?: Array<ClaimResult>;
    alwaysSendClientClaims?: boolean;
    clientClaimsPrefix?: string;
    userCodeType?: string;
    allowedScopes?: Array<string>;
    /**
     * The entire parameter collection.
     */
    properties?: { [key: string]: string; };
    protocolType?: string;
    clientName?: string;
    description?: string;
    clientUri?: string;
    logoUri?: string;
    requireConsent?: boolean;
    allowRememberConsent?: boolean;
    allowedGrantTypes?: Array<string>;
    requirePkce?: boolean;
    allowPlainTextPkce?: boolean;
    requireRequestObject?: boolean;
    /**
     * Specifies if client is enabled. Defaults to true.
     */
    enabled?: boolean;
    /**
     * Unique ID of the client.
     */
    clientId?: string;
}

