/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LogoutContextResult { 
    /**
     * Username as either an email or an ldap user
     */
    id: string;
    /**
     * client identifier
     */
    clientId?: string;
    /**
     * client name
     */
    clientName?: string;
    /**
     * post logout redirect URI
     */
    postLogoutRedirectUri?: string;
    /**
     * subject identifier for the user at logout time
     */
    subjectId?: string;
    /**
     * session identifier for the user at logout time
     */
    sessionId?: string;
    /**
     * Ids of clients known to have an authentication session for user at end session time
     */
    clientIds?: Array<string>;
    /**
     * subject identifier for the user at logout time
     */
    parameters?: { [key: string]: string; };
    /**
     * The sign out iframe URL
     */
    signOutIframeUrl?: string;
    /**
     * true if the signout prompt should be shown; otherwise, false.
     */
    showSignoutPrompt?: boolean;
}

