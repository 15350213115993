export * from './consent.api';
import { ConsentApi } from './consent.api';
export * from './login.api';
import { LoginApi } from './login.api';
export * from './profile.api';
import { ProfileApi } from './profile.api';
export * from './roles.api';
import { RolesApi } from './roles.api';
export * from './signup.api';
import { SignupApi } from './signup.api';
export * from './userAdmin.api';
import { UserAdminApi } from './userAdmin.api';
export const APIS = [ConsentApi, LoginApi, ProfileApi, RolesApi, SignupApi, UserAdminApi];
