/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientResult } from './clientResult';


export interface AuthorizationContextResult { 
    authClient?: ClientResult;
    /**
     * The display mode passed from the authorization request.
     */
    displayMode?: string;
    /**
     * The redirect URI.
     */
    redirectUri?: string;
    /**
     * The UI locales passed from the authorization request.
     */
    uiLocales?: string;
    /**
     * The external identity provider requested. This is used to bypass home realm discovery (HRD). This is provided via the \"idp:\" prefix to the acr parameter on the authorize request
     */
    idp?: string;
    /**
     * The tenant requested. This is provided via the \"tenant:\" prefix to the acr parameter on the authorize request.
     */
    tenant?: string;
    /**
     * The expected username the user will use to login. This is requested from the client via the login_hint parameter on the authorize request.
     */
    loginHint?: string;
    /**
     * The collection of prompt modes.
     */
    promptModes?: Array<string>;
    /**
     * The acr values passed from the authorization request.
     */
    acrValues?: Array<string>;
    /**
     * The entire parameter collection.
     */
    parameters?: { [key: string]: string; };
    /**
     * The validated contents of the request object (if present).
     */
    requestObjectValues?: { [key: string]: string; };
}

