/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminUserViewModel { 
    /**
     * A unique id
     */
    id: string;
    /**
     * User\'s email Address
     */
    userName: string;
    /**
     * User\'s first name
     */
    firstName: string;
    /**
     * User\'s last name
     */
    lastName: string;
    /**
     * User\'s email Address
     */
    email: string;
    /**
     * whether or not the users email has been verified
     */
    emailConfirmed: boolean;
    /**
     * User\'s phone number
     */
    phoneNumber?: string;
    /**
     * whether or not the users phone number has been verified
     */
    phoneConfirmed?: boolean;
    /**
     * Company name
     */
    companyName?: string;
    /**
     * username linked to Ideal Supply\'s Active Directory
     */
    adUsername?: string;
    roles: Array<string>;
}

