/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerAccountRegistrationResult { 
    /**
     * the id of the Customer Account Registration
     */
    id: number;
    /**
     * the customer number of the Customer Account Registration
     */
    customerNumber: string;
    /**
     * the status of the Customer Account Registration
     */
    status: string;
    /**
     * the date the request was made
     */
    requestedDate: string;
}

