/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProfileDetailsResult { 
    /**
     * A unique id
     */
    id: string;
    /**
     * User\'s email Address
     */
    userName: string;
    /**
     * User\'s first name
     */
    firstName: string;
    /**
     * User\'s last name
     */
    lastName: string;
    /**
     * User\'s email Address
     */
    email: string;
    /**
     * User\'s phone number
     */
    phone?: string;
    accountNumbers?: Array<string>;
    roles?: Array<string>;
    /**
     * A company name
     */
    company?: string;
}

