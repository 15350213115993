/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PagedResults { 
    /**
     * The total number of records that match the query
     */
    total: number;
    /**
     * The maximum number of records to return
     */
    limit: number;
    /**
     * The number of records to skip
     */
    skip: number;
    /**
     * The result records
     */
    data?: Array<object> | null;
}

