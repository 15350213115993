/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalProviderResult } from './externalProviderResult';
import { AuthorizationContextResult } from './authorizationContextResult';


export interface LoginContextResult { 
    /**
     * the url to return to after successfully logging in
     */
    returnUrl?: string;
    authorizationContext?: AuthorizationContextResult;
    /**
     * Whether or not to allow remember login to be checked
     */
    allowRememberLogin?: boolean;
    /**
     * Whether or not to allow local login
     */
    enableLocalLogin?: boolean;
    /**
     * the available external login providers
     */
    externalProviders?: Array<ExternalProviderResult>;
}

