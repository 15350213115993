/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ResourceViewModel } from './resourceViewModel';


export interface ScopeViewModel { 
    name?: string;
    value?: string;
    displayName?: string;
    description?: string;
    emphasize?: boolean;
    required?: boolean;
    checked?: boolean;
    resources?: Array<ResourceViewModel>;
}

