/**
 * IdealSupply IdentityServer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScopeViewModel } from './scopeViewModel';


export interface ConsentRequestViewModel { 
    clientName?: string;
    clientUrl?: string;
    clientLogoUrl?: string;
    allowRememberConsent?: boolean;
    returnUrl?: string;
    identityScopes?: Array<ScopeViewModel>;
    apiScopes?: Array<ScopeViewModel>;
}

