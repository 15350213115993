import { ValueProvider } from '@angular/core';
import { BASE_PATH as IdentityServerBasePath } from '@idealsupply/ngclient-webservice-identity-server';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

export const environment = {
  production: false,
  providers: [
    {
      provide: IdentityServerBasePath,
      useValue: 'https://auth-dev.idealsupply.com/v0',
    } as ValueProvider,
  ],
  ngxsPluginImports: [
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
  ],
};
